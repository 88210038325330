// src/App.js
import React, { useRef } from 'react';
import styled from 'styled-components';
import catImage from './spinning-cat.jpg'; // Place your cat image in the src directory

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  color: #000;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
`;

const MainContent = styled.div`
  font-size: 1.5em;
`;

const PlayButton = styled.button`
  background-color: #ff00cc;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #333399;
  }
`;

const CatVideoWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 20px auto;
  width: 300px;
  height: 300px;
`;

const CatVideo = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const InteractiveArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  cursor: pointer;
`;

function App() {
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  const handlePlay = () => {
    audioRef.current.play();
  };

  const handleBellyRub = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 5; // Replace with the start time of the loop in seconds
      videoRef.current.play();
      videoRef.current.loop = true;
    }
  };

  return (
    <Container>
      <Header>Sully</Header>
      <MainContent>
        <img src={catImage} className="spinning-cat" alt="Spinning Cat" />
        <p>meow meow meow meow</p>
        <PlayButton onClick={handlePlay}>Play Music</PlayButton>
        <CatVideoWrapper>
          <CatVideo ref={videoRef} src="/belly-rub.mp4" loop />
          <InteractiveArea onClick={handleBellyRub} />
        </CatVideoWrapper>
      </MainContent>
      <audio ref={audioRef} src="/jungle-music.mp3" loop />
    </Container>
  );
}

export default App;
